<template>
  <div class="accountQuery">
    <!--筛选列表-->
    <div class="sign">
      <!-- 左边 -->
      <div class="rightBtn">
        <div style="display: flex">
          <div
            v-for="(item, index) in linst"
            :key="index"
            class="defaultStyle"
            :class="codeStyle === index ? 'opp' : ''"
            @click="add(item, index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <!-- 搜索 -->
    <div class="Search">
      <el-form ref="queryForm" :model="searchData" :inline="true" class="rela">
        <el-form-item>
          <el-input
            placeholder="支付流水"
            v-model="searchData.billCode"
            clearable
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            placeholder="订单号"
            v-model="searchData.orderId"
            clearable
            type="number"
            v-limit-number-length="19"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="this.itemdata == '运费' ? false : true">
          <el-input
            placeholder="主订单号"
            v-model="searchData.groupId"
            clearable
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="this.itemdata == '运费' ? false : true">
          <el-input
            placeholder="商品名称"
            v-model="searchData.goodsName"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item v-if="this.itemdata == '运费' ? false : true">
          <el-input
            placeholder="商品ID"
            v-model="searchData.goodsId"
            clearable
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="this.itemdata == '运费' ? false : true">
          <el-input
            placeholder="生产厂家"
            v-model="searchData.manufacturer"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item v-if="this.itemdata == '运费' ? false : true">
          <el-input
            placeholder="结算对象"
            v-model="searchData.name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchData.status" placeholder="请选择">
            <el-option
              v-for="item in condition"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              clearable
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="this.itemdata == '退款' ? true : false">
          <el-select
            v-model="searchData.refundType"
            placeholder="退款类型"
            clearable
          >
            <el-option
              v-for="item in refundTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              clearable
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="
            this.itemdata == '货款' || this.itemdata == '退款' ? false : true
          "
        >
          <el-select v-model="searchData.inOutType" placeholder="请选择">
            <el-option
              v-for="item in this.inOutTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              clearable
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="searchData.orderCreationTime"
            type="datetimerange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="searchBusinessList()"
            >查询</el-button
          >
          <el-button style="margin-left: 25px" @click="reset()">重置</el-button>
        </el-form-item>
        <div
          class="export fx"
          @click="derive()"
          v-if="allJurisdiction.accountQuery129"
        >
          <img src="@/assets/img/Frame.png" alt="" class="imagesing" />
          <el-button type="text" style="margin: 0px 5px 0px 3px"
            >导出</el-button
          >
        </div>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="table">
      <el-table
        :data="tableData1"
        border
        style="width: 100%"
        :header-cell-style="{ background: '#F2F3F5', color: '#383B46' }"
        v-loading="loading"
      >
        <el-table-column
          align="center"
          fixed
          label="序号"
          type="index"
          width="70"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.index }}</p>
          </template>
        </el-table-column>
        <!-- 台账生成时间 -->
        <el-table-column
          :key="0"
          prop="user_name"
          label="台账生成时间"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.createTime }}</p>
            <!-- <p>{{ scope.row.name }}</p> -->
          </template>
        </el-table-column>
        <!-- 佣金类型 -->
        <el-table-column
          :key="1"
          prop="type"
          label="佣金类型"
          width="150"
          align="center"
          v-if="this.itemdata == '佣金'"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.type | type }}</p>
          </template>
        </el-table-column>
        <!-- 收支类型 -->
        <el-table-column
          :key="2"
          prop="user_name"
          label="收支类型"
          width="150"
          align="center"
          v-if="
            this.itemdata == '货款' || this.itemdata == '退款' ? false : true
          "
        >
          <template slot-scope="scope">
            <p>{{ scope.row.inOutType == 1 ? "收入" : "支出" }}</p>
          </template>
        </el-table-column>
        <!-- 退款类型 -->
        <el-table-column
          :key="3"
          prop="user_name"
          label="退款类型"
          width="150"
          align="center"
          v-if="this.itemdata == '退款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.refundType == 0 ? "售后" : "冲货" }}</p>
          </template>
        </el-table-column>
        <!-- 结算对象 -->
        <el-table-column
          :key="4"
          prop="user_name"
          label="结算对象"
          width="250"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.name }}</p>
            <!-- <p>{{ scope.row.name }}</p> -->
          </template>
        </el-table-column>
        <!-- 支付流水号/时间 -->
        <el-table-column
          :key="5"
          prop="user_name"
          label="支付流水号/时间"
          width="280"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.billCode }}</p>
            <p>{{ scope.row.payTime }}</p>
            <!-- <p>{{ scope.row.name }}</p> -->
          </template>
        </el-table-column>
        <!-- 订单号/时间 -->
        <el-table-column
          :key="6"
          prop="user_name"
          label="订单号/时间"
          width="280"
          align="center"
          v-if="this.itemdata == '运费' ? false : true"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.orderId }}</p>
            <p>{{ scope.row.orderCreateTime }}</p>
          </template>
        </el-table-column>
        <!-- 主订单号 -->
        <el-table-column
          :key="7"
          prop="user_name"
          label="主订单号"
          width="280"
          align="center"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.groupId }}</p>
          </template>
        </el-table-column>
        <!-- 运费（元） -->
        <el-table-column
          :key="8"
          prop="user_name"
          label="运费（元）"
          width="150"
          align="center"
          v-if="this.itemdata == '运费' ? true : false"
        >
          <template slot-scope="scope">
            <p v-if="scope.row.inOutType == 1">{{ scope.row.freightAmount }}</p>
            <p v-if="scope.row.inOutType == -1">
              {{ scope.row.returnedFreightAmount }}
            </p>
          </template>
        </el-table-column>
        <!-- 退款单号 -->
        <el-table-column
          :key="9"
          prop="user_name"
          label="退款单号"
          align="center"
          v-if="
            this.itemdata == '运费' || this.itemdata == '退款' ? true : false
          "
        >
          <template slot-scope="scope">
            <p v-if="scope.row.refundId != 0">{{ scope.row.refundId }}</p>
            <p v-if="scope.row.refundId == 0">-</p>
          </template>
        </el-table-column>
        <!-- 商品ID -->
        <el-table-column
          :key="10"
          prop="user_name"
          label="商品ID"
          width="150"
          align="center"
          v-if="this.itemdata == '运费' ? false : true"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.goodsId }}</p>
          </template>
        </el-table-column>
        <!-- 商品名称 -->
        <el-table-column
          :key="11"
          prop="user_name"
          label="商品名称"
          width="150"
          align="center"
          v-if="this.itemdata == '运费' ? false : true"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.goodsName }}</p>
          </template>
        </el-table-column>
        <!-- 规格 -->
        <el-table-column
          :key="12"
          prop="user_name"
          label="规格"
          width="150"
          align="center"
          v-if="this.itemdata == '运费' ? false : true"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.goodsSpecification }}</p>
          </template>
        </el-table-column>
        <!-- 生产厂家 -->
        <el-table-column
          :key="13"
          prop="user_name"
          label="生产厂家"
          width="200"
          align="center"
          v-if="
            this.itemdata == '运费' || this.itemdata == '退款' ? false : true
          "
        >
          <template slot-scope="scope">
            <p>{{ scope.row.manufacturer }}</p>
          </template>
        </el-table-column>
        <!-- 单价（元） -->
        <el-table-column
          :key="14"
          prop="user_name"
          label="单价（元）"
          width="150"
          align="center"
          v-if="
            this.itemdata == '运费' || this.itemdata == '退款' ? false : true
          "
        >
          <template slot-scope="scope">
            <p>{{ scope.row.actualUnitPrice }}</p>
          </template>
        </el-table-column>
        <!-- 订单数量/退款数量 -->
        <el-table-column
          :key="15"
          prop="user_name"
          label="订单数量/退款数量"
          width="150"
          align="center"
          v-if="
            (this.itemdata == '手续费' || this.itemdata == '佣金') ? true : false
          "
        >
          <template slot-scope="scope">
            <p>{{ scope.row.quantity }}</p>
          </template>
        </el-table-column>
        <!-- 佣金（元/件） -->
        <el-table-column
          :key="16"
          prop="user_name"
          label="佣金（元/件）"
          width="150"
          align="center"
          v-if="this.itemdata == '佣金' ? true : false"
        >
          <template slot-scope="scope">
            <p v-if="scope.row.type == 3">{{ scope.row.saleCommission }}</p>
            <p v-if="scope.row.type == 4">{{ scope.row.salesmanCommission }}</p>
            <p v-if="scope.row.type == 6">{{ scope.row.platformCommission }}</p>
            <p
              v-if="
                scope.row.type == 7 ||
                scope.row.type == 8 ||
                scope.row.type == 9
              "
            >
              {{ scope.row.whiteBarCommission }}
            </p>
          </template>
        </el-table-column>
        <!-- 佣金小计（元） -->
        <el-table-column
          :key="17"
          prop="user_name"
          label="佣金小计（元）"
          width="150"
          align="center"
          v-if="this.itemdata == '佣金' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.settlementTotal }}</p>
          </template>
        </el-table-column>
        <!-- 订单数量 -->
        <el-table-column
          :key="18"
          prop="user_name"
          label="订单数量"
          width="150"
          align="center"
          v-if="this.itemdata == '货款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.orderQuantity }}</p>
          </template>
        </el-table-column>
        <!-- 平台手续费（元/件） -->
        <el-table-column
          :key="19"
          prop="user_name"
          label="平台手续费"
          width="150"
          align="center"
          v-if="this.itemdata == '货款' ? true : false"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">待结算</div>
            <div v-else>{{ scope.row.platformHandlingFee }}</div>
          </template>
        </el-table-column>
        <!-- 原来叫手续费小计（元/件） -->
        <el-table-column
          :key="20"
          prop="user_name"
          label="平台手续费"
          width="150"
          align="center"
          v-if="this.itemdata == '手续费' ? true : false"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">待结算</div>
            <div v-else>{{ scope.row.settlementTotal }}</div>
          </template>
        </el-table-column>
        <!-- 商品佣金（元/件） -->
        <el-table-column
          :key="21"
          prop="user_name"
          label="商品佣金（元/件）"
          width="150"
          align="center"
          v-if="
            this.itemdata == '手续费' || this.itemdata == '货款' ? true : false
          "
        >
          <template slot-scope="scope">
            <p>{{ scope.row.commission }}</p>
          </template>
        </el-table-column>
        <!-- 结算合计（元） -->
        <el-table-column
          :key="22"
          prop="user_name"
          label="结算合计（元）"
          width="150"
          align="center"
          v-if="this.itemdata == '货款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.settlementTotal }}</p>
          </template>
        </el-table-column>
        <!-- 生产厂家 -->
        <el-table-column
          :key="24"
          prop="user_name"
          label="生产厂家"
          width="150"
          align="center"
          v-if="this.itemdata == '退款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.manufacturer }}</p>
          </template>
        </el-table-column>
        <!-- 单价 (元) -->
        <el-table-column
          :key="25"
          prop="user_name"
          label="单价 (元)"
          width="150"
          align="center"
          v-if="this.itemdata == '退款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.actualUnitPrice }}</p>
          </template>
        </el-table-column>
        <!-- 退款单价 (元) -->
        <el-table-column
          :key="26"
          prop="user_name"
          label="退款单价 (元)"
          width="150"
          align="center"
          v-if="this.itemdata == '退款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.supplierPayment }}</p>
          </template>
        </el-table-column>
        <!-- 退款数量 -->
        <el-table-column
          :key="27"
          prop="user_name"
          label="退款数量"
          width="150"
          align="center"
          v-if="this.itemdata == '退款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.quantity }}</p>
          </template>
        </el-table-column>
        <!-- 退款合计 (元)-->
        <el-table-column
          :key="28"
          prop="user_name"
          label="退款合计 (元)"
          width="150"
          align="center"
          v-if="this.itemdata == '退款' ? true : false"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.settlementTotal }}</p>
          </template>
        </el-table-column>

        <!-- 状态 -->
        <el-table-column
          :key="23"
          prop="user_name"
          label="状态"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.status == 0 ? "未分账" : "已分账" }}</p>
          </template>
        </el-table-column>
      </el-table>
      <div class="paging">
        <!-- sizes,  -->
        <el-pagination
          background
          layout=" total,prev, pager, next, jumper"
          :page-size="10"
          :total="pageTotal"
          :current-page.sync="currentPage1"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { verificationTime } from "@/utils/index.js";
const settlement = createNamespacedHelpers("settlement");
export default {
  data() {
    return {
      searchData: {
        type: 0,
        billCode: null,
        billCode: null,
        groupId: null,
        orderId: null,
        orderGroupId: null,
        orderItemId: null,
        goodsId: null,
        goodsName: null,
        manufacturer: null,
        name: null,
        status: null,
        startTime: null,
        endTime: null,
        page: 1,
        size: 10,
        refundType: null,
        inOutType: null,
        searchData: null,
      },
      tableData1: [],
      currentPage1: 1,
      tableData: {},
      condition: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 0,
          label: "未分账",
        },
        {
          value: 1,
          label: "已分账",
        },
      ],
      refundTypeList: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 0,
          label: "售后",
        },
        {
          value: 1,
          label: "冲货",
        },
      ],
      inOutTypeList: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 1,
          label: "收入",
        },
        {
          value: -1,
          label: "支出",
        },
      ],
      // 点击标记
      linst: ["货款", "退款", "运费", "手续费", "佣金"],
      codeStyle: 0, // 默认选项
      itemdata: "货款", // 什么分类
      pageTotal: 0,
      loading: false,
    };
  },
  filters: {
    type(v) {
      switch (v) {
        case 0:
          return "货款";
        case 1:
          return "退款";
        case 2:
          return "运费";
        case 3:
          return "销售佣金";
        case 4:
          return "销售佣金";
        case 5:
          return "平台手续费";
        case 6:
          return "销售佣金";
        case 7:
          return "白条佣金";
        case 8:
          return "白条佣金";
        case 9:
          return "白条佣金";
      }
    },
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.searchData = this.detailsSeach[router];
      this.searchData.orderCreationTime =
        this.detailsSeach[router].orderCreationTime;
      let type = this.detailsSeach[router].type;
      if (type == 0) {
        this.codeStyle = 0;
      } else if (type == 1) {
        this.codeStyle = 1;
      } else if (type == 2) {
        this.codeStyle = 2;
      } else if (type == 3) {
        this.codeStyle = 3;
      } else if (type == 5) {
        this.codeStyle = 4;
      }
      // this.tableData.orderCreationTime
    }

    this.inquire();
  },

  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "mainHright",
      "allJurisdiction",
      "detailsSeach",
    ]),
    ...settlement.mapState(["BillList", "BillTotal", "BillPage"]),
  },

  methods: {
    ...settlement.mapActions([
      "getBillList",
      "billExport",
      "billRefundExport",
      "billFreightExport",
      "billChargedExport",
      "billCommissionExport",
    ]),
    ...commonIndex.mapMutations(["changeSeach"]),
    // 搜索列表
    searchBusinessList() {
      this.searchData.page = 1;
      this.currentPage1 = 1;
      this.inquire();
    },
    // 查询列表
    async inquire() {
      if (this.searchData.orderCreationTime) {
        // console.log(this.tableData.orderCreationTime)
        // (this.searchData.startTime = this.dateFormat(
        //   this.tableData.orderCreationTime[0]
        // )),
        //   (this.searchData.endTime = this.dateFormat(
        //     this.tableData.orderCreationTime[1]
        //   ));
        this.searchData.startTime = this.searchData.orderCreationTime[0];
        this.searchData.endTime = this.searchData.orderCreationTime[1];
      } else {
        this.searchData.startTime = null;
        this.searchData.endTime = null;
      }
      this.loading = true;
      let data = await this.getBillList(this.searchData);
      this.loading = false;
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.searchData };
      save.orderCreationTime = this.searchData.orderCreationTime;
      save.router = router;
      this.changeSeach(save);

      if (data.data.list) {
        data.data.list.forEach((v, i) => {
          v.orderCreateTime = this.format(v.orderCreateTime);
          let index = (data.data.pageNum - 1) * data.data.pageSize + i + 1;
          this.$set(v, "index", index);
        });
      }
      this.tableData1 = data.data.list;
      this.pageTotal = data.data.total;
    },
    // 重置
    reset() {
      this.searchData.billCode = "";
      this.searchData.billCode = null;
      this.searchData.orderId = null;
      this.searchData.groupId = null;
      this.searchData.orderGroupId = null;
      this.searchData.goodsId = null;
      this.searchData.goodsName = null;
      this.searchData.manufacturer = null;
      this.searchData.name = null;
      this.searchData.status = null;
      this.tableData = {};
      this.searchData.endTime = null;
      this.searchData.startTime = null;
      this.searchData.refundType = null;
      this.searchData.inOutType = null;
      this.searchData.orderCreationTime = null;
      this.searchBusinessList();
    },
    // 点击筛选
    add(item, index) {
      this.itemdata = item;
      this.codeStyle = index;
      this.judgeClick();
      if (item == "货款") {
        this.searchData.type = 0;
      } else if (item == "退款") {
        this.searchData.type = 1;
      } else if (item == "运费") {
        this.searchData.type = 2;
      } else if (item == "佣金") {
        this.searchData.type = 3;
      } else if (item == "手续费") {
        this.searchData.type = 5;
      }
      this.reset();
      // this.searchBusinessList();
    },
    // 导出
    async derive() {
      // let boo = verificationTime(
      //   this.searchData.startTime,
      //   this.searchData.endTime
      // );
      // if (!boo) return;
      let data = {};
      if (this.itemdata == "货款") {
        // data = await this.billExport();
        downLoadZip(
          `/settlement/bill/export`,
          "xlsx",
          "货款列表导出",
          this.searchData,
          "php",
          "get"
        );
      } else if (this.itemdata == "退款") {
        // data = await this.billRefundExport();
        downLoadZip(
          `/settlement/bill/refundExport`,
          "xlsx",
          "退款列表导出",
          this.searchData,
          "php",
          "get"
        );
      } else if (this.itemdata == "运费") {
        // data = await this.billFreightExport();
        downLoadZip(
          `/settlement/bill/freightExport`,
          "xlsx",
          "运费列表导出",
          this.searchData,
          "php",
          "get"
        );
      } else if (this.itemdata == "佣金") {
        // data = await this.billCommissionExport();
        downLoadZip(
          `/settlement/bill/commissionExport`,
          "xlsx",
          "佣金列表导出",
          this.searchData,
          "php",
          "get"
        );
      } else if (this.itemdata == "手续费") {
        // data = await this.billChargedExport();
        downLoadZip(
          `/settlement/bill/chargedExport`,
          "xlsx",
          "手续费列表导出",
          this.searchData,
          "php",
          "get"
        );
      }
      if (data.code == "200") {
        window.open(data.data.url);
      }
    },
    // 筛选判断
    judgeClick() {
      //   if (this.itemdata == "全部") {
      //     this.afterSalesOrderListData({page:this.currentPage1});
      //   } else if (this.itemdata == "待审核")
    },
    // 分页
    handleCurrentChange(item) {
      this.$set(this.searchData, "page", item);
      this.inquire();
    },
    // 时间格式转化
    dateFormat(dateData) {
      var date = new Date(dateData);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    // 时间戳转化时分秒
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dateQuery {
  display: flex;
  align-items: center;
  justify-content: c;
  .queryTitle,
  .fterText {
    font-size: 14px;
    margin-right: 10px;
  }
  .fterText {
    margin-left: 20px;
  }
  .searchBtn {
    margin-left: 20px;
  }
}
.accountQuery {
  background: #fff;
  border-radius: 10px;
  padding: 20px 16px;
}
.Search {
  width: 100%;
  .search-box {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .sch-1 {
    margin-top: 10px;
    width: 360px;
    // min-width: 200px;
    display: flex;
    .sch-title {
      width: 100px;
      min-width: 100px;
      height: 100%;
      font-size: 13px;
      background-color: #f5f7fa;
      color: #909399;
      border: 1px solid #dcdfe6;
      border-right: none;
      border-radius: 4px 0px 0px 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 0px 0px 5px !important;
    }
    .el-select {
      width: 100%;
    }
    .sch-title2 {
      // min-width: 110px;
    }
  }
  .sch-2 {
    margin-right: 0.5%;
  }
  .time {
    margin-top: 10px;
    width: 100%;
    display: flex;
    .inquire {
      display: flex;
      width: 600px;
      justify-content: space-between;
      div {
        flex: 1;
      }
    }
  }
  .searchBtn {
    margin-left: 20px;
  }
}
.sign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  color: rgba(28, 31, 35, 0.6);
  margin-bottom: 25px;
}
.opp {
  color: #06b7ae;
  border-radius: 5px;
  background-color: #f2f2f2;
}
.defaultStyle {
  width: 100px;
  padding: 10px 20px;
  text-align: center;
  line-height: 100%;
  font-size: 14px;
  cursor: pointer;
}
.paging {
  margin-top: 10px;
  text-align: right;
}
.table {
  margin-top: 10px;
}
::v-deep .el-input--suffix .el-input__inner {
  line-height: 30px;
}
.export {
  position: absolute;
  bottom: 20px;
  right: 0px;
}
.imagesing {
  height: 18px;
  margin-top: 10px;
  margin-right: 3px;
}
.el-form {
  display: flex;
  flex-wrap: wrap; // 允许流式布局
  gap: 0px 15px; // 给每个输入框加外边距
}
</style>
